import './App.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaFax } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { useState, useEffect } from 'react';

function App() {
  const [submitStatus, setSubmitStatus] = useState('');

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );
      console.log('SUCCESS!', result.text);
      setSubmitStatus('success');
      e.target.reset();
    } catch (error) {
      console.error('FAILED...', error);
      setSubmitStatus('error');
    }
    setTimeout(() => setSubmitStatus(''), 5000);
  };

  return (
    <div className="App">
      <header className="App-header">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 80" style={{ width: '100%', maxWidth: '600px', height: 'auto' }}>
          {/* Background */}
          <rect width="600" height="80" fill="#243154"/>
          
          {/* Company Name */}
          <text 
            x="300" 
            y="50" 
            fontFamily="Georgia, Times New Roman, Times, serif" 
            fontSize="48" 
            fontWeight="bold" 
            textAnchor="middle" 
            fill="#c0a875"
          >
            Tuckahoe Enterprises
          </text>
        </svg>
      </header>

      <main>
        <div className="info-container">
          <section className="contact-info">
            <h2><FaMapMarkerAlt className="icon" /> Contact Information</h2>
            <p>
              <strong><FaMapMarkerAlt className="icon" /> Address:</strong><br />
              <span className="indent">
                8901 Three Chopt Rd, Ste A1<br />
                Henrico, VA 23229
              </span>
            </p>
            <p>
              <strong><FaPhone className="icon" /> Phone:</strong><br />
              <span className="indent">
                <a href="tel:+18042132593">(804) 213-2593</a>
              </span>
            </p>
            <p>
              <strong><FaFax className="icon" /> Fax:</strong><br />
              <span className="indent">
                <a href="fax:+18045970018">(804) 597-0018</a>
              </span>
            </p>
            <p>
              <strong><FaEnvelope className="icon" /> Email:</strong><br />
              <span className="indent">
                <a href="mailto:contact@tuckahoeenterprises.com">
                  contact@tuckahoeenterprises.com
                </a>
              </span>
            </p>
          </section>

          <section className="hours">
            <h2><FaClock className="icon" /> Business Hours</h2>
            <div className="hours-grid">
              <div className="hours-row">
                Coming soon
                {/* Monday - Friday: 9:00 AM - 5:00 PM<br />
                Saturday: By appointment<br />
                Sunday: Closed */}
              </div>
            </div>
          </section>

          <section className="contact-form">
            <h2><FaEnvelope className="icon" /> Send us a Message</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="from_name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="from_email"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <input
                type="hidden"
                name="to_name"
                value="Tuckahoe Enterprises"
              />
              <button
                type="submit"
                className="submit-button"
                disabled={submitStatus === 'sending'}
              >
                {submitStatus === 'sending' ? 'Sending...' : 'Send Message'}
              </button>
              {submitStatus === 'success' && (
                <p className="status-message success">Message sent successfully!</p>
              )}
              {submitStatus === 'error' && (
                <p className="status-message error">Failed to send message. Please try again.</p>
              )}
            </form>
          </section>
        </div>
      </main>

      <footer>
        <p>&copy; {new Date().getFullYear()} Tuckahoe Enterprises, LLC</p>
      </footer>
    </div>
  );
}

export default App;
